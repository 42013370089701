/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveBreaks, resolveMeta } from "@dataResolvers";
import { ThankYou as PageTemplate } from "@pageTemplates";

export const query = graphql`
query {
  craft {
    entry(section: "thankYou") {
      ... on Craft_thankYou_thankYou_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Thank you message
        heading0
        descriptor0
        linkField {
          text
          url
        }
        relatedProjects {
          title
          url
          ... on Craft_project_project_Entry {
            heroBackgroundImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            projectCategories {
              title
              slug
            }
          }
        }
      }
    }
  }
}
`;

const previewQuery = `
query {
  craft {
    entry(section: "thankYou") {
      ... on Craft_thankYou_thankYou_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Thank you message
        heading0
        descriptor0
        linkField {
          text
          url
        }
        relatedProjects {
          title
          url
          ... on Craft_project_project_Entry {
            heroBackgroundImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            projectCategories {
              title
              slug
            }
          }
        }
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const {
    title,
    uid,
    url,
    slug,
    metaTitle,
    metaDescription,
    metaImage,
    // Thank you message
    heading0,
    descriptor0,
    relatedProjects,
    linkField,
  } = craft.entry;

  return {
    meta: resolveMeta({
      title,
      metaTitle,
      metaImage,
      metaDescription,
      slug,
      url,
    }),
    thankYou: {
      heading: heading0,
      subhead: descriptor0,
      projects: relatedProjects.map(project => {
        return {
          title: project.title,
          description: project.heroDescription,
          image: resolveImage(project.heroBackgroundImage),
          url: project.url,
          categories: project.projectCategories.map( category => {
            return {
              title: category.title,
              slug: category.slug,
            };
          }),
        };
      }),
      link: linkField,
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
